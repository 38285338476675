import ALargeSmallIcon from "./ALargeSmall";
import DropboxIcon from "./DropboxIcon";
import GoogleDriveIcon from "./GoogleDriveIcon";
import GoogleIcon from "./GoogleIcon";
import LoadingIcon from "./LoadingIcon";
import OpenInNewWindowIcon from "./OpenInNewWindowIcon";
import PauseIcon from "./PauseIcon";
import PlayIcon from "./PlayIcon";
import TvMinimalIcon from "./TvMinimalIcon";

export * from "./brands";

export {
  ALargeSmallIcon,
  DropboxIcon,
  GoogleDriveIcon,
  GoogleIcon,
  LoadingIcon,
  OpenInNewWindowIcon,
  PauseIcon,
  PlayIcon,
  TvMinimalIcon,
};
