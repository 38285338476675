import React from "react";

export default function TvMinimalIcon(props: React.SVGProps<SVGSVGElement>) {
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M7 21h10"
      strokeWidth="2"
      stroke="white"
    />
    <rect
      width="20"
      height="14"
      x="2"
      y="3"
      rx="2"
      strokeWidth="2"
      stroke="white"
    />
  </svg>
}
