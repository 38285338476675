import { v4 as uuidv4 } from "uuid";


export * from "./brands";
export * from "./cachedStringify";
export * from "./colors";
export * from "./dates";
export * from "./filenames";
export * from "./hash";
export * from "./graphics"
export * from "./override";
export * from "./sleep";
export * from "./stringify";



export function filterUndefined<T>(items?: (T | undefined)[]): T[] {
  if (!items) return [];
  return items.filter(item => !!item) as T[];
}


type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

function isObject(item: any): item is object {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export function deepMerge<T extends object>(target: T, source: DeepPartial<T>, depth: number = Infinity): T {
  if (depth === 0 || !isObject(source) || !isObject(target)) {
      return target;
  }

  for (const key in source) {
      if (source.hasOwnProperty(key)) {
          const targetValue = target[key];
          const sourceValue = source[key];

          if (isObject(targetValue) && isObject(sourceValue)) {
              target[key] = deepMerge(targetValue, sourceValue, depth - 1);
          } else {
              target[key] = sourceValue as any;
          }
      }
  }

  return target;
}


export function splitByFirstOccurrence(input: string, delimiter: string): [string, string] {
  const parts = input.split(delimiter);
  if (parts.length === 1) {
    return [input, ''];
  }
  return [parts[0], parts.slice(1).join(delimiter)];
}


/*
export function requireJsonFile(filePath: string): Record<string, any> | undefined {
  try {
    // Attempt to require the JSON file
    return require(filePath);
  } catch (error: any) {
    if (error.code === 'MODULE_NOT_FOUND') {
      // The file does not exist, return an empty object
      return undefined;
    } else {
      // Handle other errors
      console.error(error);
      return undefined;
    }
  }
}
*/

export function getUuid() {
  return uuidv4();
}

export function getDiscordLink() {
  return "https://discord.gg/9awV6A4Gqw"
}


type NamedClassConstructor<T extends NamedClass> = {
  new(...args: any[]): T;
  className: string;
  isType(obj: any): obj is T;
};

export abstract class NamedClass {
  static readonly className: string = ''; // Now it always has a string value (even if empty)

  static isType<T extends NamedClass>(this: new (...args: any[]) => T, obj: any): obj is T {

    return obj instanceof this && obj.__className === (this as NamedClassConstructor<T>).className;
  }

  __className: string;

  constructor() {
    if (!this.constructor.hasOwnProperty('className') || !(this.constructor as any).className) {
      throw new Error('Subclasses of NamedClass must set the static className property.');
    }
    this.__className = (this.constructor as any).className;
  }
}

export class ClassAttribute<T> {
  // Type guard for subclasses
  static isType<T>(this: { new(...args: any[]): T }, obj: any): obj is T {
    return typeof obj === 'object' && obj !== null && obj.__className === this.name;
  }

  // Get type attribute from the class name
  protected static getTypeAttribute(): string {
    return `__className`;
  }

  // Set the unique attribute for subclasses in the constructor
  constructor() {
    const typeAttr = (this.constructor as typeof ClassAttribute).getTypeAttribute();
    (this as any)[typeAttr] = this.constructor.name;
  }
}

export class CheckableType<T> {
  // Type guard for subclasses
  static isType<T>(obj: any): obj is T {
    return typeof obj === 'object' && obj !== null && this.getTypeAttribute() in obj;
  }

  // Get type attribute from the class name
  protected static getTypeAttribute(): string {
    return `__isType__${this.name}`;
  }

  // Set the unique attribute for subclasses in the constructor
  constructor() {
    const typeAttr = (this.constructor as typeof CheckableType).getTypeAttribute();
    (this as any)[typeAttr] = true;
    this.__typeAttribute = typeAttr;
  }

  __typeAttribute: string;
}

export function isValidEmail(email: string) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

export function areSetsEqual(a: Set<string>, b: Set<string>): boolean {
  return a.size === b.size && Array.from(a).every(value => b.has(value));
}

export function getTimeRemainingAbbreviated(timestamp: number): string {
  const now = new Date().getTime(); // Current time in milliseconds

  // Calculate the difference in milliseconds
  const timeDifference = timestamp - now;

  // Calculate the difference in hours
  const hoursRemaining = Math.floor(timeDifference / (1000 * 60 * 60));

  // If less than 24 hours, represent as hours
  if (hoursRemaining < 24) {
    return `${hoursRemaining}h`;
  }

  // Calculate the difference in days and represent as days
  const daysRemaining = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  return `${daysRemaining}d`;
}

export function getTimeSinceAbbreviated(timestamp: number): string {
  const now = new Date().getTime(); // Current time in milliseconds

  const timeDifference = now - timestamp; // Difference in milliseconds

  const seconds = timeDifference / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;
  const weeks = days / 7;
  const months = days / 30;
  const years = days / 365;

  if (seconds < 1) {
    return "just now";
  } else if (seconds < 60) {
    return `${Math.floor(seconds)}s ago`;
  } else if (minutes < 60) {
    return `${Math.floor(minutes)}m ago`;
  } else if (hours < 24) {
    return `${Math.floor(hours)}h ago`;
  } else if (days < 7) {
    return `${Math.floor(days)}d ago`;
  } else if (weeks < 4) {
    return `${Math.floor(weeks)}w ago`;
  } else if (months < 12) {
    return `${Math.floor(months)}mo ago`;
  } else {
    return `${Math.floor(years)}y ago`;
  }
}


export function hasTimestampPassed(timestamp: number): boolean {
  const now = new Date().getTime(); // Current time in milliseconds
  return timestamp < now;
}


export function formatTimestamp(timestamp: number): string {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const options: Intl.DateTimeFormatOptions = {
    timeZone,
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  };

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(timestamp);
  const [monthAndDay, _] = formattedDate.split(', ');
  return `${monthAndDay}`;

}


// https://www.petermorlion.com/iterating-a-typescript-enum/
export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

export function withoutEmptyProperties<T extends { [key: string]: any }>(obj: T): Partial<T> {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => !!value)
  ) as Partial<T>;
}

export function stringifyFirstLevel(obj: unknown) {
  return  JSON.stringify(obj, function (k, v) { return k && v && typeof v !== "number" ? (Array.isArray(v) ? "[object Array]" : "" + v) : v; });
}

type CacheKey = string;
type CacheValue<T> = { result: T; timestamp: number };

export const memoize = <T extends (...args: any[]) => any>(
  fn: T,
  timeout: number = -1
): T => {
  const cache = new Map<CacheKey, CacheValue<ReturnType<T>>>();

  return ((...args: Parameters<T>): ReturnType<T> => {
    const key: CacheKey = JSON.stringify(args);

    if (cache.has(key)) {
      const cachedValue = cache.get(key) as CacheValue<ReturnType<T>>;
      const isExpired = timeout > -1 && Date.now() - cachedValue.timestamp > timeout;

      if (!isExpired) {
        return cachedValue.result;
      }
    }

    const result = fn(...args);
    cache.set(key, { result, timestamp: Date.now() });
    return result;
  }) as T;
};

export function getNextCopyString(input: string): string {
  const regex = /^(.*\(Copy )([0-9]+)\)$/;

  const match = input.match(regex);
  if (match && match[1] && match[2]) {
    const prefix = match[1];
    const copyNumber = parseInt(match[2], 10);
    return `${prefix}${copyNumber + 1})`;
  }

  return `${input} (Copy 1)`;
}

export function toLowerCaseHyphenDelimited(input: string): string {
  const regex = /[^a-z0-9-]+/gi;
  const hyphenDelimiter = "-";

  const sanitizedString = input
    .replace(regex, hyphenDelimiter) // Replace any non-alphanumeric characters (excluding hyphen) with hyphens
    .replace(/-{2,}/g, hyphenDelimiter) // Replace multiple consecutive hyphens with a single hyphen
    .replace(/^-|-$/g, "") // Remove any leading or trailing hyphens
    .toLowerCase(); // Convert the entire string to lowercase

  return sanitizedString;
}

export function convertToCopyFormat(input: string): string {
  const regex = /^(.*-copy)([0-9]+)$/;

  const match = input.match(regex);
  if (match && match[1] && match[2]) {
    const baseName = match[1].slice(0, -5); // Remove "-copy" from the match
    const copyNumber = parseInt(match[2], 10);
    return `${baseName} (Copy ${copyNumber})`;
  }

  return input;
}

export enum StorageProviders {
  DROPBOX = 0,
  GOOGLE = 1,
}

export function getLinkType(url: string): StorageProviders | null {
  const dropboxRegex = /^https?:\/\/(www\.)?dropbox\.com/;
  const googleRegex = /^https?:\/\/(www\.)?(drive\.google\.com|docs\.google\.com|photos\.google\.com)/;

  if (dropboxRegex.test(url)) {
    return StorageProviders.DROPBOX;
  } else if (googleRegex.test(url)) {
    return StorageProviders.GOOGLE;
  } else {
    return null;
  }
}
